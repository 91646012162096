import { AlertModal } from "@/components/modal";
import useAnnouncement from "@/hooks/useAnnouncement";
import React from "react";

const Alert = () => {
  const { loading, announcements, open, close } = useAnnouncement();
  if (loading) return <></>;
  return <AlertModal items={announcements} open={open} close={close} />;
};

export default Alert;