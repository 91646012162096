import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Divider, Stack, Typography, alpha, useTheme } from "@mui/material";
import { useAppModalStore } from "@/stores/appModalStore";
import { Icon } from "@iconify/react";
import { useRouter } from "next/navigation";
import useFetchProvidersList from "@/hooks/fetchers/useProvider";

type Menu = {
  key: string;
  label: string;
  icon?: string;
};

const menus: Menu[] = [
  {
    key: "wallets",
    label: "กระเป๋าเงิน",
    icon: "solar:wallet-broken",
  },
  {
    key: "historyAll",
    label: "ประวัติธุรกรรม",
    icon: "mdi:history",
  },
  // {
  //   key: "transferWallet",
  //   label: "โยกเงิน",
  //   icon: "solar:round-transfer-horizontal-broken",
  // },
  {
    key: "affiliate",
    label: "แนะนำเพื่อน",
    icon: "mdi:invite",
  },
  {
    key: "refund",
    label: "คืนยอดเสีย",
    icon: "gridicons:refund",
  },
  {
    key: "promotion",
    label: "โปรโมชั่น",
    icon: "ph:gift-fill",
  },
  {
    key: "coupon",
    label: "คูปอง",
    icon: "mdi:coupon",
  },
  {
    key: "wheel",
    label: "กงล้อ",
    icon: "lucide:ferris-wheel",
  },
  {
    key: "mysteryBox",
    label: "กล่องสุ่ม",
    icon: "ph:archive-bold",
  },
  {
    key: "campaign",
    label: "แจกจัดหนัก",
    icon: "emojione-v1:trophy",
  },
];

const Item = styled(Box)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: theme.spacing(1),
  textAlign: "center",
  "& #icon": {
    color: theme.palette.primary.main,
    ...((theme.name === "preset3" ||
      theme.name === "preset5" ||
      theme.name === "preset6" ||
      theme.name === "preset12" ||
      theme.name === "preset13" ||
      theme.name === "preset15" ||
      theme.name === "preset17" ||
      theme.name === "preset25") && {
      color: "white",
    }),
    ...(theme.name === "preset27" && {
      color: "black",
    }),
  },
  color: theme.palette.text.secondary,
  transition: "all 0.12s ease-in-out",
  "&:hover": {
    transform: "scale(1.08)",
    cursor: "pointer",
    "& #icon-container": {
      background: theme.palette.primary.main,
      ...(theme.name === "preset14" && {
        background: "#790e50",
      }),
    },
    "& #icon": {
      color: theme.palette.getContrastText(theme.palette.primary.main),
      ...(theme.name === "preset14" && {
        color: "#fff",
      }),
    },
    "& #lebel": {
      fontWeight: 600,
      color: theme.palette.primary.main,
      ...(theme.name === "preset5" && {
        color: "#86c01a",
      }),
      ...(theme.name === "preset14" && {
        color: "#fff",
      }),
    },
  },
}));

const StyledBox = styled(Box)(({ theme }) => ({
  boxShadow: theme.shadows[8],
  cursor: "pointer",
  border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
  background: alpha(theme.palette.secondary.main, 0.6),
  ...((
    theme.name === "preset3" ||
    theme.name === "preset6" ||
    theme.name === "preset12" ||
    theme.name === "preset13" ||
    theme.name === "preset15" ||
    theme.name === "preset16" ||
    theme.name === "preset25" ||
    theme.name === "preset27"
  ) && {
    background: theme.gradient[100],
    border: "none",
  }),
  ...(theme.name === "preset5" && {
    background: theme.gradient[100],
    border: "none",
  }),
  ...(theme.name === "preset14" && {
    backgroundColor: "#fc8fb2",
  }),
  ...(theme.name === "preset17" && {
    background: theme.gradient[600],
    border: "none",
  }),
  color: theme.palette.getContrastText(theme.palette.primary.main),
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: 64,
  width: 64,
  borderRadius: 100,
  transition: "all 0.12s ease-in-out",
}));

export default function MainMenu() {
  const theme = useTheme();
  const router = useRouter();
  const { isUfaEnabled, isBetflixEnabled } = useFetchProvidersList();
  const [setOpen, setData] = useAppModalStore((state) => [
    state.setOpen,
    state.setData,
  ]);

  // console.log('isUfaEnabled', isUfaEnabled)
  // console.log('isBetflixEnabled', isBetflixEnabled)

  const filteredMenus =
    isUfaEnabled || isBetflixEnabled
      ? theme.name === 'preset13'
        ? menus.filter(
          (menu) => !["refund"].includes(menu.key)
        )
        : menus.filter(
          (menu) => !["campaign"].includes(menu.key)
        )
      : theme.name === 'preset13'
        ? menus.filter(
          (menu) => !["transferWallet", "wallets"].includes(menu.key)
        )
        : menus.filter(
          (menu) => !["transferWallet", "wallets", "campaign"].includes(menu.key)
        )

  const handleItemClick = (key: string) => {
    if (key === "mystery-box") {
      router.push("/mystery-box");
    } else {
      setOpen(true);
      setData(key);
    }
  };

  return (
    <Stack spacing={2}>
      <Divider />
      <Grid
        className="main-menu"
        container
        justifyContent={"center"}
        alignItems={"start"}
      >
        {filteredMenus.map((menu) => (
          <Grid
            onClick={() => handleItemClick(menu.key)}
            key={menu.key}
            item
            md={1}
            xs={3}
          >
            <Item gap={1}>
              <StyledBox id="icon-container">
                <Icon
                  id="icon"
                  icon={menu?.icon || ""}
                  width={42}
                  height={42}
                />
              </StyledBox>
              <Typography id="lebel" fontWeight={"500"} variant="button" sx={{ color: (theme) => theme.gradient[920] }}>
                {menu.label}
              </Typography>
            </Item>
          </Grid>
        ))}
      </Grid>
      <Divider />
    </Stack>
  );
}
