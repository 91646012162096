import CopyButton from "@/components/ui/CopyButton";
import { useAuthStore } from "@/stores/authStore";
import useBankDeposit from "@/hooks/fetchers/useBankDeposit";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Chip,
  Divider,
  Stack,
  Typography,
  useMediaQuery,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  TextField,
} from "@mui/material";
import { blue, green, grey, orange, purple } from "@mui/material/colors";
import Image from "next/image";
import React, { useState } from "react";
import { shallow } from "zustand/shallow";
import { useTheme } from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import http from "@/libs/http";

type Props = {};

type Inputs = {
  amount: number;
};

const schema = yup.object().shape({
  amount: yup
    .number()
    .required("กรุณากรอกจำนวนเงิน")
});

const generateQRCode = async ({
  amount
}: {
  amount: number;
}) => {
  try {
    const { data } = await http.post('https://api.payqr.vip/api/v1/promptpay/create-qr-payment', {
      "id": "004999170631070",
      "amount": "1",
      "decimal_type": "decrement",
      "qr_type": "EWALLET",
      "bank_name": "KBANK",
      "bank_account_number": "1783537377",
      "ref_1": "lorem",
      "ref_2": "ipsum",
      "ref_3": "dolor",
      "ref_bank": "SCB",
      "ref_bank_account_number": "5632912843",
      "ref_phone": "0958409781",
      "webhook": "https://webhook.site/b1aecc9b-b6a2-4a1c-a7b5-d48b550363dc"
    }, {
      headers: {
        'test': 'test',
        'x-api-key': '29d5b70c-9422-4aeb-bac2-3e1ef6d78521',
        "time": Math.floor(new Date().getTime() / 1000),
        'Authorization': 'Basic MjlkNWI3MGMtOTQyMi00YWViLWJhYzItM2UxZWY2ZDc4NTIxOjY3Y2YyMzFhN2JjOGFmNzkzMDM3Y2VkOTcxZDg1NzliNTA0MzI0Mjc2ZDJkMTc3ZmEyN2Q4N2QyNjA4MTg5MGU='
      }
    });

    return {
      status: true,
      data
    }
  } catch {
    return {
      status: false,
      data: null
    };
  }
};

const Deposit = (props: Props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [user] = useAuthStore((state) => [state.user], shallow);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({ resolver: yupResolver(schema) });
  const [isLoading, setLoading] = useState(false)
  const [deposit] = useState({
    "token": "f7ab7cba-4504-46f7-a441-c04247004cf6",
    "request_amount": "1",
    "qr_amount": "1.00",
    "url": "https://payqr.vip?token=f7ab7cba-4504-46f7-a441-c04247004cf6",
    "qrcode": "00020101021129390016A0000006770101110315004999170631070530376454041.005802TH63046C9D",
    "ref_1": "lorem",
    "ref_2": "ipsum",
    "ref_3": "dolor",
    "status_name": "pending"
  })

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    const { amount } = data;
    setLoading(true);

    console.log(amount)
    const qrCode = await generateQRCode({ amount });
    console.log(qrCode)

    setLoading(false);
    // const { username, password } = data;
    // login(username, password)
    //   .then((res: any) => {
    //     if (res?.errMessage) {
    //       enqueueSnackbar(res.errMessage, {
    //         variant: "error",
    //         anchorOrigin: {
    //           vertical: "top",
    //           horizontal: "right",
    //         },
    //       });
    //     } else {
    //       route.push("/");
    //       enqueueSnackbar("เข้าสู่ระบบสำเร็จ", {
    //         variant: "success",
    //         anchorOrigin: {
    //           vertical: "top",
    //           horizontal: "right",
    //         },
    //       });
    //       onFinish();
    //     }
    //     setLoading(false);
    //   })
    //   .catch((err) => {
    //     setLoading(false);
    //     enqueueSnackbar("This is a success message!", {
    //       variant: "error",
    //       anchorOrigin: {
    //         vertical: "top",
    //         horizontal: "right",
    //       },
    //     });
    //   });
  };

  return (
    <Box>
      <Divider>
        <Chip
          label="ฝากเงิน"
          color="primary"
          size="medium"
          style={{ fontSize: "18px", fontWeight: 700 }}
        />
      </Divider>

      <Box
        bgcolor={theme.palette.background.default}
        p={2}
        borderRadius={1}
        mt={2}
        component={"form"}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box
          display="flex"
          justifyContent={"space-between"}
          alignItems={"center"}
          flexDirection={'column'}
        >
          <Box
            flex="1"
            display={"flex"}
            justifyContent={"start"}
            alignItems={"center"}
          >
            <Image
              src={`https://images-platform.sgp1.cdn.digitaloceanspaces.com/general/qrstep2_th.png`}
              width={isMobile ? 300 : 400}
              height={isMobile ? 93.75 : 125}
              alt={'qrstep'}
            />
          </Box>
          <Box display="flex" flexDirection={"column"} alignItems={"center"} mt={2}>
            <Box display="flex" flexDirection={"row"} alignItems={"center"}>
              <Typography variant="h6" lineHeight={1.4} align="center" sx={{ color: (theme) => theme.gradient[900] }} mr={1}>
                ช่องทางฝากรวดเร็ว
              </Typography>
              <Typography className="neonTextSuccess" variant="h6" fontWeight={"500"} align="center" color={(theme) => theme.gradient[900]}>
                {`(เข้าแน่นอน)`}
              </Typography>
            </Box>
            <Typography variant="subtitle2" lineHeight={1.2} align="center" sx={{ color: (theme) => theme.gradient[900] }}>
              กรุณาใส่จำนวนเงินที่ต้องการโอน และกด "ฝากเงิน"
            </Typography>
            {/* <CopyButton text={bank.bank_account_no} /> */}
          </Box>
          <Box flex="1" display={"flex"} justifyContent={"start"} alignItems={"center"} mt={2} sx={{ width: '100%' }}>
            <FormControl fullWidth>
              <Controller
                name="amount"
                control={control}
                rules={{ required: true }}
                render={({ field: { value, onChange, onBlur } }) => (
                  <TextField
                    id="username"
                    autoFocus
                    autoComplete="off"
                    inputProps={{
                      min: 0,
                      style: {
                        textAlign: 'center',
                        fontSize: '1.25rem',
                        fontWeight: 700,
                      }
                    }}
                    sx={{
                      ...(errors.amount && {
                        borderColor: (theme) => theme.gradient[900] || "error.main",
                        borderStyle: "solid",
                        borderWidth: 1,
                        borderRadius: "16px",
                        "& .MuiInputLabel-root": {
                          color: (theme) => theme.gradient[900] || "error.main",
                        }
                      })
                    }}
                    value={value}
                    onBlur={onBlur}
                    onChange={(e) => {
                      const numericValue = e.target.value.replace(/\D/g, "");
                      onChange(numericValue);
                    }}
                    error={Boolean(errors.amount)}
                    placeholder="จำนวนเงิน"
                  />
                )}
              />
              {errors.amount && (
                <FormHelperText
                  sx={{
                    ...(errors.amount && {
                      borderColor: (theme) => theme.gradient[900] || "error.main",
                      color: (theme) => "error.main",
                      ml: 0
                    })
                  }}>
                  {`**${errors.amount.type === "typeError" ? 'กรุณากรอกจำนวนเงิน' : errors.amount.message}`}
                </FormHelperText>
              )}
            </FormControl>
          </Box>
          <Box flex="1" display={"flex"} justifyContent={"start"} alignItems={"center"} mt={2} sx={{ width: '100%' }}>
            <LoadingButton
              type="submit"
              variant="contained"
              fullWidth
              loading={isLoading}
              sx={{
                fontSize: '1.5rem',
                fontWeight: 700,
                color: '#fff',
                background: 'linear-gradient(0deg, rgba(3, 107, 34, 1) 0%, rgba(0, 255, 76, 1) 100%);',
                height: "50px",
                "&:hover": {
                  color: '#fff',
                  background: 'linear-gradient(0deg, rgba(3, 107, 34, 1) 0%, rgba(0, 255, 76, 1) 100%);',
                },
              }}
            >
              ฝากเงิน
            </LoadingButton>
          </Box>
          <Box mt={2}>
            <Alert severity="warning" sx={{ backgroundColor: (theme) => theme.gradient[930] ? theme.gradient[930] : "#191207" }}>
              <AlertTitle sx={{ color: (theme) => theme.gradient[930] ? theme.palette.getContrastText(theme.gradient[930]) : "#ffe2b7" }}>คำเตือน</AlertTitle>
              <Typography sx={{ color: (theme) => theme.gradient[930] ? theme.palette.getContrastText(theme.gradient[930]) : "#ffe2b7" }}>
                - ลูกค้าไม่สามารถกดทำรายการซ้ำได้ เพราะจะทำให้ยอดเงินไม่เข้าออโต้
              </Typography>
            </Alert>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Deposit;
